import { Graph } from '@/model/api/Graphs';
import { CRUD } from './base/crud';

class GraphsService extends CRUD<Graph, any> {

    public fetchPieServiceMonthData(entityId: number, year: string, month: string) {
        return this.get<Graph[]>(`pos/${entityId}/graph/pie-service-month/?year=${year}&month=${month}`);
    }

    public fetchLineSoldMonthData(entityId: number, year: string, month: string) {
        return this.get<Graph[]>(`pos/${entityId}/graph/line-service-month/?year=${year}&month=${month}`);
    }

    public fetchCashMonthData(entityId: number, year: string, month: string) {
        return this.get<Graph[]>(`pos/${entityId}/graph/cash-by-month/?year=${year}`);
    }

    public fetchPieSoldByServiceYearData(entityId: number, year: string) {
        return this.get<Graph[]>(`pos/${entityId}/graph/cash-by-service-year/?year=${year}`);
    }
    
    public fetchCashByServiceMonthData(entityId: number, year: string, month: string) {
        return this.get<Graph[]>(`pos/${entityId}/graph/cash-by-service-month/?year=${year}`);
    }

}

export const graphsService = new GraphsService();