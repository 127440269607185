<template>
  <div class="page standard">

    <section class="header">
      <div class="p-grid">
      <div class="p-col-12">
        <h1>{{ $t("pos.graphs_and_statistics.title") }}</h1>
        <p>{{ $t("pos.graphs_and_statistics.subtitle") }}</p>

        <!--
        <Fieldset legend="Informazioni" :toggleable="true" :collapsed="true">
          {{ $t('admin.pos_categories.detail.subtitle')+$t('admin.pos_categories.detail.subtitle')+$t('admin.pos_categories.detail.subtitle')+$t('admin.pos_categories.detail.subtitle') }}
        </Fieldset>
        -->

      </div>
      </div>
    </section>

    <section class="content ">
      
      <div class="header">

        <div class="p-grid">

            <div class="p-col-4">
                <div class="card">
                  <label>
                      {{ $t('generic.filter.selectyear') }}
                  </label>
                  <Dropdown 
                    v-model="selectedYear" 
                    :options="comboYears" 
                    optionLabel="name" 
                    optionValue="value"
                    :placeholder="$t('generic.filter.selectyear')"
                    :filter="true"
                    @change="reloadAllGraphs($event,'Y')"
                  />
                </div>
            </div>

            <div class="p-col-4">
                <div class="card">
                  <label>
                      {{ $t('generic.filter.selectmonth') }}
                  </label>
                  <Dropdown 
                    v-model="selectedMonth" 
                    :options="comboMonths" 
                    optionLabel="name" 
                    optionValue="value"
                    :placeholder="$t('generic.filter.selectmonth')"
                    :filter="true"
                    @change="reloadAllGraphs($event,'M')"
                  />

                </div>
              </div>

        </div>
        
        <div class="p-grid">

          <div class="p-col-6 p-chart">
            <div class="card">
              <Chart ref="serviceMonthChart" type="pie" :data="serviceMonthData" :options="serviceMonthOptions" />
            </div>
          </div>

          <div class="p-col-6 p-chart">
            <div class="card">
              <Chart ref="soldMonthChart" type="line" :data="soldMonthData" :options="soldMonthOptions" />
            </div>
          </div>

        </div>

        <Divider></Divider>
          
        <div class="p-grid">

          <div class="p-col-6 p-chart">
              <div class="card">
                <Chart ref="cashMonthChart" type="bar" :data="cashMonthData" :options="cashMonthOptions" />
              </div>
          </div>

          <div class="p-col-6 p-chart">
              <div class="card">
                <Chart ref="soldByServiceChart" type="doughnut" :data="soldByServiceData" :options="soldByServiceOptions" />
              </div>
          </div>

        </div>

        <Divider></Divider>
        
        <div class="p-grid">
          <div class="p-col-2 p-chart">
          </div>
          <div class="p-col-8 p-chart">
              <div class="card">
                <Chart ref="serviceComparisonChart" type="horizontalBar" :data="serviceComparisonData" :options="serviceComparisonOptions" />
              </div>
          </div>
          <div class="p-col-2 p-chart">
          </div>
        </div>

        <!--
        <Divider></Divider>
        
        <div class="p-grid">

          <div class="p-col-6 p-chart">
              <div class="card">
                <Chart ref="totalFeedbackChart" type="polarArea" :data="totalFeedbackData" :options="totalFeedbackOptions" />
              </div>
          </div>
          
          <div class="p-col-6 p-chart">
              <div class="card">
                <Chart ref="buyedMonthChart" type="bar" :data="buyedMonthData" :options="buyedMonthOptions" />
              </div>
          </div>

        </div>
        -->

      </div>

    </section>
  </div>

</template>

<script>
import { ref } from 'vue'
import Chart from 'primevue/chart'
import { graphsService } from "@services/graphs.service";
import { authStore } from '@/modules/auth/store';
import { convertMonthNames } from "@/utils/utils";

export default {

  name: 'PosGraphs',

  components: {
    Chart,
  },


  /******************
   * the combobox function and data load must to stay in data() declaration!
   * otherwise the dropdown doesn't display the selected data!
   */
  data() 
  {
    function getYearsForFilter() {
      const anno = new Date().getFullYear();

      return [
        {name: anno, value: anno},
        {name: (anno-1), value: (anno-1)},
        {name: (anno-2), value: (anno-2)},
        {name: (anno-3), value: (anno-3)}
      ];
    }

    return {
      
      // export function to convert month names and get combo years
      getYearsForFilter,

      // export year combobox
      selectedYear: null,
      comboYears: getYearsForFilter(),

      // export month combobox
      selectedMonth: null,
      comboMonths: [
        {name: convertMonthNames('1'), value: '1'},
        {name: convertMonthNames('2'), value: '2'},
        {name: convertMonthNames('3'), value: '3'},
        {name: convertMonthNames('4'), value: '4'},
        {name: convertMonthNames('5'), value: '5'},
        {name: convertMonthNames('6'), value: '6'},
        {name: convertMonthNames('7'), value: '7'},
        {name: convertMonthNames('8'), value: '8'},
        {name: convertMonthNames('9'), value: '9'},
        {name: convertMonthNames('10'), value: '10'},
        {name: convertMonthNames('11'), value: '11'},
        {name: convertMonthNames('12'), value: '12'}
      ],

    }
  },



  setup() 
  {
    const serviceMonthChart = ref();
    const soldByServiceChart = ref();

    const cashMonthChart = ref();
    const soldMonthChart = ref();

    const serviceComparisonChart = ref();


    // const buyedMonthChart = ref();
    // const totalFeedbackChart = ref();

    var v_year = new Date().getFullYear();
    var v_month = new Date().getMonth() + 1;

    function reloadAllGraphs(event,type) {
      
      if (v_year=='') v_year = '2022';
      if (v_month=='') v_month = '07';

      if (event)
      {
          // console.log(event);
          if (type=='Y') v_year = event.value;
          if (type=='M') v_month = event.value;
      }

      // const v_filterYear = filterYear.value;
      // console.log(selectedYear);
      // console.log(selectedMonth);

      this.loadPieServiceMonthData(v_year,v_month);
      this.loadLineSoldMonthData(v_year,v_month);
      this.loadCashMonthData(v_year);
      this.loadPieSoldByServiceYearData(v_year);
      this.loadServiceComparisonData(v_year);

    }

    function generateRandomHexColor() {
      const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
      if (randomColor.length !== 7) {
        return generateRandomHexColor();
      } else {
        return randomColor;
      }
    }

    async function loadPieServiceMonthData(year, month) {

      const response = await graphsService.fetchPieServiceMonthData(authStore.getters.me.entity_id,year,month);

      const dtgrap = response;
      // console.log(dtgrap);

      var c_data = [];
      var c_labels = [];
      var c_colors = [];

      dtgrap.forEach((row) => {
          c_data.push(row.norders);
          c_labels.push(row.service_name);
          c_colors.push(generateRandomHexColor());
      });

      const chart = serviceMonthChart.value.chart;
      chart.data.datasets[0].data = c_data;
      chart.data.labels = c_labels;
      // chart.data.datasets[0].backgroundColor = c_colors;

      var chartTitle = 'Servizi venduti '+year;
      if (month>0) chartTitle += ' mese '+convertMonthNames(month);
      chart.options.title.text = chartTitle;
      
      chart.update();

    }



    async function loadLineSoldMonthData(year, month) {

      const response = await graphsService.fetchLineSoldMonthData(authStore.getters.me.entity_id,year,month);

      const dtgrap = response;
      
      // console.log(dtgrap);

      var c_data = [0,0,0,0,0,0,0,0,0,0, 0,0,0,0,0,0,0,0,0,0, 0,0,0,0,0,0,0,0,0,0, 0];

      dtgrap.forEach((row) => {
          c_data[(row.theday)-1] = row.norders;
      });

      const chart = soldMonthChart.value.chart;
      chart.data.datasets[0].data = c_data;

      var chartTitle = 'Servizi venduti per giorno anno '+year;
      if (month>0) chartTitle += ' mese '+convertMonthNames(month);
      chart.options.title.text = chartTitle;

      chart.update();

    }


    async function loadCashMonthData(year) {

      const response = await graphsService.fetchCashMonthData(authStore.getters.me.entity_id,year);

      const dtgrap = response;
      
      // console.log(dtgrap);

      var c_data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      dtgrap.forEach((row) => {
          c_data[(row.themonth)-1] = row.totcash;
      });

      const chart = cashMonthChart.value.chart;
      chart.data.datasets[0].data = c_data;

      var chartTitle = 'Incassi per mese anno '+year;
      chart.options.title.text = chartTitle;

      chart.update();

    }


    async function loadPieSoldByServiceYearData(year) {

      const response = await graphsService.fetchPieSoldByServiceYearData(authStore.getters.me.entity_id,year);

      const dtgrap = response;
      // console.log(dtgrap);

      var c_data = [];
      var c_labels = [];
      var c_colors = [];

      dtgrap.forEach((row) => {
          c_data.push(row.totcash);
          c_labels.push(row.service_name);
          c_colors.push(generateRandomHexColor());
      });

      const chart = soldByServiceChart.value.chart;
      chart.data.datasets[0].data = c_data;
      chart.data.labels = c_labels;
      // chart.data.datasets[0].backgroundColor = c_colors;

      var chartTitle = 'Incasso per servizo anno '+year;
      chart.options.title.text = chartTitle;
      
      chart.update();

    }


    async function loadServiceComparisonData(year) {

      const yearprev = year-1;

      const resp_prev = await graphsService.fetchCashByServiceMonthData(authStore.getters.me.entity_id,yearprev);
      const resp_now = await graphsService.fetchCashByServiceMonthData(authStore.getters.me.entity_id,year);

      const dtgrapP = resp_prev;
      const dtgrapN = resp_now;
      
//console.log(dtgrapP);
//console.log(dtgrapN);

      var ardata = [];
      var alldata = [];

      var c_data_ap = [];
      var c_data_ac = [];
      
      var c_labels = [];
      
      var found = false;

      // previous year
      dtgrapP.forEach((row) => {
          ardata=[];
          ardata['service']=row.service_name;
          ardata['year']=row.theyear;
          ardata['month']=row.themonth;
          ardata['cash']=parseFloat(row.totcash);

          alldata.push(ardata);
      });
      // this year
      dtgrapN.forEach((row) => {
          ardata=[];
          ardata['service']=row.service_name;
          ardata['year']=row.theyear;
          ardata['month']=row.themonth;
          ardata['cash']=parseFloat(row.totcash);

          alldata.push(ardata);
      });

// console.log(alldata);

      // now define c_labels
      alldata.forEach((row) => {
          // check if data exists in labels
          found = false;
          c_labels.forEach((label) => {
            if (row.service == label)
            {
              found = true;
            }
          });
          if (!found) c_labels.push(row.service);
      });


      // define c_data for the two years
      c_labels.forEach((label) => 
      {
        alldata.forEach((row) => 
        {
          if (row.service == label)
          {

            // check ap
            found = false;
            c_data_ap.forEach((cdata) => {
              if (cdata.service == row.service)
              {
                found = true;
                if (cdata.year == row.year) cdata.cash = cdata.cash + row.cash;
              }
            });
            if (!found) 
            {
              if (row.year == yearprev) 
              {
                ardata=[];
                ardata['service']=row.service;
                ardata['year']=row.year;
                ardata['cash']=row.cash;

                c_data_ap.push(ardata);
              }
              else
              {
                ardata=[];
                ardata['service']=row.service;
                ardata['year']=yearprev;
                ardata['cash']=0;

                c_data_ap.push(ardata);
              }
            }

            
            // check ac
            found = false;
            c_data_ac.forEach((cdata) => {
              if (cdata.service == row.service)
              {
                found = true;
                if (cdata.year == row.year) cdata.cash = cdata.cash + row.cash;
              }
            });
            if (!found) 
            {
              if (row.year == year) 
              {
                ardata=[];
                ardata['service']=row.service;
                ardata['year']=row.year;
                ardata['cash']=row.cash;

                c_data_ac.push(ardata);
              }
              else
              {
                ardata=[];
                ardata['service']=row.service;
                ardata['year']=year;
                ardata['cash']=0;

                c_data_ac.push(ardata);
              }
            }

          }
        });
      });

      // console.log('c_data_ap = ',c_data_ap);
      // console.log('c_data_ac = ',c_data_ac);

      // create arrays of values
      var c_data_ap_val = [];
      var c_data_ac_val = [];
      c_data_ap.forEach((dat) => {
        c_data_ap_val.push(dat.cash);
      });
      c_data_ac.forEach((dat) => {
        c_data_ac_val.push(dat.cash);
      });

      const chart = serviceComparisonChart.value.chart;
      chart.data.labels = c_labels;

      chart.data.datasets[0].label = "€ Anno "+yearprev;
      chart.data.datasets[0].data = c_data_ap_val;
      
      chart.data.datasets[1].label = "€ Anno "+year;
      chart.data.datasets[1].data = c_data_ac_val;

      var chartTitle = 'Comparazione incasso servizi venduti  '+yearprev+' / '+year;
      chart.options.title.text = chartTitle;

      chart.update();

    }









    return {
      
      // export function to reload all graphs
      reloadAllGraphs,


      /*********
      * piechart services per month
      */

      serviceMonthChart,
      loadPieServiceMonthData,

      serviceMonthData: {
        labels: [
            ''
            ],
        datasets: [
            {
                data: [ 0 ],
                backgroundColor: [ "#42A5F5", "#66BB6A", "#FFA726", "#cf70e0", "#FFCE56" ],
                hoverBackgroundColor: [ "#64B5F6", "#81C784", "#FFB74D", "#d284e0", "#f7dc96" ]
            }
        ]
      },
      serviceMonthOptions: {
          responsive: true,
          legend: {
              position : 'left',
              labels: {
                  color: '#495057',
                  fontSize : 10
              }
          },
          plugins: {
              legend: {
                position: 'left',
              }
          },
          title: {
            display: true,
            text: 'loading...'
          }
      },
      


      /*********
      * line chart service sold monthly
      */

      soldMonthChart,
      loadLineSoldMonthData,
      soldMonthData: {
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        datasets: [
          {
            label: 'Servizi venduti per giorno',
            data: [0 ],
            fill: false,
            borderColor: '#42A5F5',
            tension: .4
          }
        ]
      },
      soldMonthOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          }
        },
        title: {
          display: true,
          text: 'loading...'
        }
      },
      
      

      /*********
      * bar chart cash per month
      */

      cashMonthChart,
      loadCashMonthData,
      cashMonthData: {
        labels: [
            'Gen'
            ,'Feb'
            ,'Mar'
            ,'Apr'
            ,'Mag'
            ,'Giu'
            ,'Lug'
            ,'Ago'
            ,'Set'
            ,'Ott'
            ,'Nov'
            ,'Dic'
            ],
        datasets: [
          {
            label: '€ servizi venduti',
            data: [0],
            backgroundColor: '#66BB6A',
          }
        ]
      },
      cashMonthOptions: {
          responsive: true,
          plugins: {
              legend: {
                position: 'left',
              }
          },
          title: {
            display: true,
            text: 'loading...'
          }
      },


      /*********
      * doughnut chart sold by service
      */

      
      soldByServiceChart,
      loadPieSoldByServiceYearData,
      soldByServiceData: {
        labels: [
            '--'
        ],
        datasets: [
            {
                data: [0],
                backgroundColor: ["#FF6384","#36A2EB","#FFCE56", "#cf70e0" ],
                hoverBackgroundColor: ["#FF6384","#36A2EB","#FFCE56", "#d284e0"]
            }
        ]
      },
      soldByServiceOptions: {
        legend: {
            position : 'right',
            labels: {
                color: '#495057',
                fontSize : 10
            }
        },
				plugins: {
          legend: {
              labels: {
                  color: '#495057',
              }
          }
        },
        title: {
          display: true,
          text: 'loading...'
        }
      },

      
      
      
      
      
      
      /*********
      * horizontal bar chart service comparison
      */

      
      serviceComparisonChart,
      loadServiceComparisonData,
      serviceComparisonData: {
        labels: [
            'loading...'
            ],
        datasets: [
            {
                label: 'loading...',
                backgroundColor: '#d6d6d6',
                data: [0]
            },
            {
                label: 'loading...',
                backgroundColor: '#42A5F5',
                data: [0]
            }
        ]
      },
      serviceComparisonOptions: {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        },
        title: {
          display: true,
          text: 'loading...'
        }
      },
      
      



  /*********
  * 
  * 
  * 
  * 
  * 
  * 
  *  TODO!!!! grafici fissi da rifare!
  * 
  * 
  * 
  * 
  * 
  */







      /*********
      * bar chart buyed / available per month
      */
      /*
      buyedMonthChart,
      
      buyedMonthData: {
        labels: [
            'Gen'
            ,'Feb'
            ,'Mar'
            ,'Apr'
            ,'Mag'
            ,'Giu'
            ,'Lug'
            ,'Ago'
            ,'Set'
            ,'Ott'
            ,'Nov'
            ,'Dic'
            ],
        datasets: [
          {
            label: 'Disponibili alla vendita',
            data: [120, 90, 85, 30, 224, 76, 40],
            backgroundColor: '#FFA726',
          },
          {
            label: 'Venduti',
            data: [110, 84, 85, 27, 218, 70, 40],
            backgroundColor: '#66BB6A',
          }

        ]
      },
      buyedMonthOptions: {
          responsive: true,
          plugins: {
              legend: {
                position: 'left',
              }
          },
          title: {
            display: true,
            text: 'Rapporto esami disponibili / venduti anno 2022'
          }
      },
      */



      /*********
      * polar chart total feedback
      */

      /*
      totalFeedbackChart,
      totalFeedbackData: {
        labels: [
            '1 stella'
            ,'2 stelle'
            ,'3 stelle'
            ,'4 stelle'
            ,'5 stelle'
        ],
        datasets: [
            {
                data: [7,5,15,30,18],
                backgroundColor: ["#f24441","#FFA726","#e5dd70","#45c5ef","#45ef50"],
                hoverBackgroundColor: ["#f26360","#FFB74D","#eae59d","#6fcded","#45efa5"],
                label: 'Feedback'
            }
        ]
      },
      totalFeedbackOptions: {
        legend: {
            position : 'right',
            labels: {
                color: '#495057',
                fontSize : 10
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: '#ebedef'
                }
            }
        },
        title: {
          display: true,
          text: 'Feedback ricevuti dai clienti'
        }
      },
      */
      
      
      
      
      
      
    }
  },

  mounted() {

    this.reloadAllGraphs(null,null);

  },
}
</script>